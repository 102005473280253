import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import {store} from "../../App";
import { connect } from 'react-redux';
import { unsetLegitCall } from "../../Redux/TrackerActions";
import { setDialog, clearDialog, setToast, setLoader, clearLoader, showScreenBlocker, hideScreenBlocker } from "../../Redux/OverlayActions";
import { askForPermissionToReceiveNotifications } from '../../push-notification';

class CallView extends Component {
    constructor() {
        super();
        this.state = {
            naam: window.localStorage.getItem('naam') || '',
            gsm: window.localStorage.getItem('gsm') || '',
            email: window.localStorage.getItem('email') || '',
            aantal: '',
            aantalOptions: [],
            langskomen: '',
            langskomenOptions: [],
            motivatie: '',
            subscribe: false,
            accept: false,

            naamValid: ((window.localStorage.getItem('naam') || '') == "" ? "" : "is-valid"),
            gsmValid: ((window.localStorage.getItem('gsm') || '') == "" ? "" : "is-valid"),
            emailValid: "",
            aantalValid: "",
            langskomenValid: "",
            motivatieValid: "",
            subscribeValid: "",
            acceptValid: "",

            continuesValidation: false,

            redirectToTracker: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmitFailure = this.handleSubmitFailure.bind(this);
        this.handleSubmitSucces = this.handleSubmitSucces.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.postForm = this.postForm.bind(this);
        this.fillLangskomenArray = this.fillLangskomenArray.bind(this);
        this.fillAantalArray = this.fillAantalArray.bind(this);

        this.checkPermissionNotification = this.checkPermissionNotification.bind(this);
        this.requestNotifications = this.requestNotifications.bind(this);
        this.preRequestPermissionNotifications = this.preRequestPermissionNotifications.bind(this);
        this.handlePreRequestPermissionNotificationsResponse = this.handlePreRequestPermissionNotificationsResponse.bind(this);
    }

    componentDidMount() {
        if (!this.props.legitCall) {
            store.dispatch(unsetLegitCall());
            store.dispatch(setToast({
                message: "Er ging iets mis!",
                theme: "error"
            }));
            this.setState({
                redirectToTracker: true,
            });
        }

        if (this.state.naam != "") {
            document.getElementById("formAantal").focus();
            document.getElementById("formAantal").scrollIntoView();
        }
        this.fillLangskomenArray();
        this.fillAantalArray();
    }

    fillLangskomenArray() {
        let time = new Date();
        time.setMinutes(Math.ceil(time.getMinutes() / 30) * 30);

        let midnight = new Date();
        midnight.setHours(0);
        midnight.setMinutes(0);
        midnight.setSeconds(0);
        midnight.setMilliseconds(0);
        midnight.setDate(midnight.getDate() + 1);

        let arr = [];
        for (;time < midnight; time = new Date(time.getTime() + 15*60000)) {
            arr.push(<option value={time.getHours()+":"+time.getMinutes()+":00"}>{time.getHours()+"u"+(time.getMinutes() == 0 ? time.getMinutes()+"0" : time.getMinutes())}</option>);
        }
        arr.push(<option value="23u59">23u59</option>);

        this.setState({
            langskomenOptions: arr,
            langskomen: arr[0].props.value,
        });
    }

    fillAantalArray() {
        let arr = [];
        arr.push(<option value="1-2">1-2 personen</option>);
        arr.push(<option value="3-4">3-4 personen</option>);
        arr.push(<option value="5-6">5-6 personen</option>);
        arr.push(<option value="7-8">7-8 personen</option>);
        arr.push(<option value="9-10">9-10 personen</option>);
        arr.push(<option value="10+">Meer dan 10 personen</option>);

        this.setState({
            aantalOptions: arr,
            aantal: arr[0].props.value,
        });
    }

    validateForm(showToast) {
        let ret = true;
        let message = "Sommige gegevens zijn niet correct ingevuld!";

        let naamValid= "is-valid";
        let gsmValid= "is-valid";
        let emailValid= "is-valid";
        let aantalValid= "is-valid";
        let langskomenValid= "is-valid";
        let motivatieValid= "is-valid";
        let subscribeValid= "is-valid";
        let acceptValid= "is-valid";

        if (this.state.naam == "") {
            naamValid = "is-invalid";
            ret = false;
        }
        if (this.state.aantal == "") {
            aantalValid = "is-invalid";
            ret = false;
        }

        if (this.state.langskomen == "") {
            langskomenValid = "is-invalid";
            ret = false;
        }

        if (this.state.gsm == "") {
            gsmValid = "is-invalid";
            ret = false;
        }

        if (this.state.email == "" && this.state.subscribe) {
            emailValid = "is-invalid";
            ret = false;
        }

        if (!this.state.accept) {
            acceptValid = "is-invalid";
            if (ret) {
                message = "Gelieve te aanvaarden dat een oproep geen garantie is!";
            }
            ret = false;
        }

        this.setState({
            naamValid: naamValid,
            gsmValid: gsmValid,
            emailValid: emailValid,
            aantalValid: aantalValid,
            langskomenValid: langskomenValid,
            motivatieValid: motivatieValid,
            subscribeValid: subscribeValid,
            acceptValid: acceptValid,
        });
        
        if (!ret && showToast) {
            store.dispatch(setToast({
                message: message,
                theme: "error"
            }));
        }
        return ret;
    }

    async handleChange(event) {
        const {name, value, type, checked} = event.target;
        const target = event.target;
        let cursor = -1;
        
        if (target.setSelectionRange &&
            (
                type === 'text' ||
                type === 'search' ||
                type === 'password' ||
                type === 'url' ||
                type === 'tel'
            )
        ) {
            cursor = ('selectionStart' in event.target ? (event.target.selectionStart ?? -1) : -1);
        }
        await type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value });
        if (cursor != -1 && target.setSelectionRange) {
            target.setSelectionRange(cursor, cursor);
        }
        if (this.state.continuesValidation) {
            this.validateForm(false);
        }
    }

    handleCancel(event) {
        event.preventDefault();
        this.setState({
            redirectToTracker: true,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        if (!this.validateForm(true)) {
            this.setState({
                continuesValidation: true,
            });
        } else {
            this.checkPermissionNotification();
        }
    }

    checkPermissionNotification() {
        const that = this;
        //Check if device isn't iOS
        //Rendering the question mood
        const isIOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
        if (isIOSDevice) {
            //Device is iOS
            //Notifications won't work anyway
            that.postForm('');
        } else {
            navigator.permissions && navigator.permissions.query({name: 'notifications'}).then(function(PermissionStatus) {
                if('granted' === PermissionStatus.state) {
                    //Notifications permission granted
                    that.requestNotifications();
                } else if('denied' === PermissionStatus.state) {
                    //Notifications permission denied
                    that.postForm('');
                } else {
                    //Notifications permission unknown
                    that.preRequestPermissionNotifications();
                }
            });
            if (!navigator.permissions) {
                //This browser does not have the permissions API
                //Fallback to localstorage polyfill
                const geolocationState = localStorage.getItem('notifications') || '';
                if('granted' === geolocationState) {
                    //Notifications permission granted
                    that.requestNotifications();
                } else if('denied' === geolocationState) {
                    //Notifications permission denied
                    that.postForm('');
                } else {
                    //Notifications permission unknown
                    that.preRequestPermissionNotifications();
                }
            }
        }        
    }

    preRequestPermissionNotifications() {
        const that = this;
        store.dispatch(setDialog({
            title: "Wil je een melding ontvangen als de IJskar onderweg is?",
            message: "Als je toegang tot push-meldingen toestaat, kunnen we je een notificatie sturen als de IJskar naar jou onderweg is. Indien je dit liever niet toestaat zullen we in de plaats daarvan een SMS sturen.",
            btn1Text: "Verwittig mij!",
            btn1Title: "accept",
            btn1Theme: "primary",
            btn2Text: "Liever niet",
            btn2Title: "deny",
            response: that.handlePreRequestPermissionNotificationsResponse,
        }));
    }

    handlePreRequestPermissionNotificationsResponse(response) {
        store.dispatch(clearDialog());
        if (response == "accept") {
            this.requestNotifications();
        } else if (response == "deny" || response == "dismiss") {
            this.postForm('');
        }
    }

    async requestNotifications() {
        store.dispatch(showScreenBlocker());
        const permissionResponse = await askForPermissionToReceiveNotifications();
        console.log(permissionResponse);
        store.dispatch(hideScreenBlocker());

        let token = "";
        if (permissionResponse.status == "SUCCESS") {
            localStorage.setItem('notifications', "granted");
            token = permissionResponse.token;
            this.postForm(token);
        } else {
            localStorage.setItem('notifications', "denied");
            this.postForm('');
        }
    }

    postForm(token) {
        store.dispatch(setLoader({
            title: "Even geduld...",
            message: "Je oproep wordt geplaatst",
        }));

        window.localStorage.setItem('naam', this.state.naam);
        window.localStorage.setItem('gsm', this.state.gsm);
        window.localStorage.setItem('email', this.state.email);
        window.localStorage.setItem('token', token);

        let formData = new FormData();
        formData.append('gpslat', (window.localStorage.getItem('gpslat') || ''));
        formData.append('gpslong', (window.localStorage.getItem('gpslong') || ''));
        formData.append('latitude', (window.localStorage.getItem('latitude') || ''));
        formData.append('longitude', (window.localStorage.getItem('longitude') || ''));
        formData.append('straat', (window.localStorage.getItem('straat') || ''));
        formData.append('nr', (window.localStorage.getItem('nr') || ''));
        formData.append('gemeente', (window.localStorage.getItem('gemeente') || ''));
        formData.append('postcode', (window.localStorage.getItem('postcode') || ''));
        formData.append('land', (window.localStorage.getItem('land') || ''));
        formData.append('placeid', (window.localStorage.getItem('placeid') || ''));
        formData.append('adres', (window.localStorage.getItem('adres') || ''));
        formData.append('naam', this.state.naam);
        formData.append('gsm', this.state.gsm);
        formData.append('email', this.state.email);
        formData.append('aantal', this.state.aantal);
        formData.append('langskomen', this.state.langskomen);
        formData.append('motivatie', this.state.motivatie);
        formData.append('subscribe', this.state.subscribe);
        formData.append('available', (this.props.availableNearby) ? 1 : 0);
        formData.append('udid', (window.localStorage.getItem('udid') || ''));
        formData.append('token', token);
        formData.append('versioninfo', window.versionInfo || "");

        fetch(process.env.REACT_APP_API_HREF+"legacycopy.php?action=add_oproep",{
            method: 'POST', 
            body: formData,
        }).then(function(response) {
            store.dispatch(clearLoader());
            return response.json();
        }).then(data => {
            if (data.status == 201) {
                this.handleSubmitSucces();
            } else {
                this.handleSubmitFailure();
            }
        }).catch(error => {
            this.handleSubmitFailure();
        });
    }

    handleSubmitFailure() {
        store.dispatch(setToast({
            message: "Er ging iets mis bij het plaatsen van je oproep. Probeer het later opnieuw.",
            theme: "error"
        }));
    }

    handleSubmitSucces() {
        const date = new Date();
        const now = date.getTime();
        window.localStorage.setItem('lastCallTime', now);

        store.dispatch(setToast({
            message: "Je oproep is geplaatst!",
        }));

        this.setState({
            redirectToTracker: true,
        });
    }
    
    render() {
        if (this.state.redirectToTracker === true) {
            return <Redirect to='/' />
        }

        return (
            <div className="content container">
                <h2>Oproep plaatsen</h2>
                <p>Vul de laatste gegevens in om een ijskar tot bij jullie te roepen</p>

                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="formName">Naam</label>
                        <input                            
                            type="text"
                            value={this.state.naam}
                            name="naam" 
                            placeholder="Bv. Madame Blanche" 
                            onChange={this.handleChange} 
                            id="formName"
                            className={"form-control "+this.state.naamValid}
                        />
                        <div className="invalid-feedback">Gelieve een naam in te voeren</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="formCellPhone">GSM-nummer</label>
                        <input                            
                            type="tel"
                            value={this.state.gsm} 
                            name="gsm" 
                            placeholder="Bv. 04123456789" 
                            onChange={this.handleChange} 
                            id="formCellPhone"
                            className={"form-control "+this.state.gsmValid}
                        />
                        <div className="invalid-feedback">Gelieve een gsm-nummer in te geven zodat we u kunnen verwittigen als de ijksar onderweg is</div>
                        <small className="form-text text-muted">
                        Wij gebruiken uw gsm-nummer uitsluitend om u te waarschuwen als de ijskar naar u toe komt of om u te bellen indien de chauffeur uw huis niet vindt
                        </small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="formEmail">Email (optioneel)</label>
                        <input                            
                            type="email"
                            value={this.state.email} 
                            name="email" 
                            placeholder="blanche@foubert.eu" 
                            onChange={this.handleChange} 
                            id="formEmail"
                            className={"form-control "+this.state.emailValid}
                        />
                        <div className="invalid-feedback">Gelieve een geldig emailadres in te geven</div>
                        <small className="form-text text-muted">
                        Indien u graag onze nieuwsbrief ontvangt, kunt u hier uw emailadres ingeven
                        </small>
                    </div>
                    {/* <label>
                        <input 
                            type="checkbox" 
                            name="subscribe"
                            checked={this.state.subscribe}
                            onChange={this.handleChange}
                        /> Schrijf me in voor de Foubert-nieuwsbrief!
                    </label> */}

                    <hr />

                    <div className="form-group">
                        <label htmlFor="formAantal">Aantal personen</label>
                        <select
                            value={this.state.aantal}
                            name="aantal"
                            id="formAantal"
                            className={"form-control "+this.state.aantal}
                            onChange={this.handleChange}
                        >
                            {this.state.aantalOptions}
                        </select>
                        <div className="invalid-feedback">Gelieve het aantal personen in te voeren die een ijsje willen</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="formUntil">Langskomen mag tot</label>
                        <select
                            value={this.state.langskomen}
                            name="langskomen"
                            id="formUntil"
                            className={"form-control "+this.state.langskomenValid}
                            onChange={this.handleChange}
                        >
                            {this.state.langskomenOptions}
                        </select>
                        <div className="invalid-feedback">Gelieve aan te geven tot hoe laat de ijskar mag langskomen. Dit tijdstip moet uiteraard in de toekomst liggen</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="formMotivation">Extra info (optioneel)</label>
                        <textarea 
                            value={this.state.motivatie}
                            name="motivatie" 
                            placeholder="Bv. Wij geven een kotfeestje!" 
                            onChange={this.handleChange}
                            rows="3"
                            className={"form-control "+this.state.motivatieValid}
                            id="formMotivation"
                        />
                    </div>
                    <div className="form-group" style={{marginTop: "1rem"}}>
                        <label>
                            <input 
                                type="checkbox" 
                                name="accept"
                                checked={this.state.accept}
                                onChange={this.handleChange}
                            /> Ik snap dat een oproep GEEN GARANTIE is dat er effectief EEN IJSKAR LANGSKOMT
                        </label>
                    </div>
                    <br />

                    <input type="submit" className="btn btn-primary text-light col-12 mb-4" value="Roep de IJskar!" />
                    <button style={{backgroundColor: "#7A7A7A"}} className="btn text-light btn-sm col-12 mt-2 mb-2" onClick={this.handleCancel}>Annuleer</button>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        legitCall: state.tracker.legitCall || false,
        availableNearby: state.tracker.availableNearby || false,
    }
}

export default connect(mapStateToProps)(CallView);