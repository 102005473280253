import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import ErrorImage from '../ErrorImage';
import LoginOption from './LoginOption';
import NumericButton from './NumericButton';
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';
import { setToast, setLoader, clearLoader } from "../../Redux/OverlayActions";


class CadeauView extends Component {
    constructor(match) {
        super();
        this.state = {
            hasInternet: true,
            cadeauchequeimage: "",
            exp_date: "",
            barcode: match.match.params.barcode || null,
            isValidCode: null,
            restValue: null,
            isUsed: null,
            login: null,
            displayLogin: true,
            name: null,
            logins: null,
            paymentAmount: "",
        };

        this.getChequeInfo = this.getChequeInfo.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleNumericButtonClick = this.handleNumericButtonClick.bind(this);
        this.handleSubmitPaymentButtonClick = this.handleSubmitPaymentButtonClick.bind(this);
        this.handleSubmitFailure = this.handleSubmitFailure.bind(this);
        this.handleSubmitSucces = this.handleSubmitSucces.bind(this);
    }
    
    componentDidMount() {
        if (this.state.barcode != null) {
            this.getChequeInfo(this.state.barcode);
        }
    }

    getChequeInfo(barcode) {
        const url = "https://intranet.foubert.eu/api/cadeau.php?action=get_cadeaucheque_info&barcode="+barcode+((window.localStorage.getItem('passphrase') || false) != false ? "&passphrase="+window.localStorage.getItem('passphrase') : "")+((window.localStorage.getItem('zaakids') || false) != false ? "&zaakids="+window.localStorage.getItem('zaakids') : "");
        fetch(url,{
            method: 'POST', 
        })
        .then(response => response.json())
        .then(data => {
            const responsecode = data.status;
            if (responsecode == 200) {
                const exp_date = new Date(data.result.exp_date);
                this.setState({
                    hasInternet: true,
                    isValidCode: true,
                    restValue: data.result.rest_value,
                    cadeauchequeimage: data.result.cadeauchequeimage,
                    exp_date: exp_date.getDate()+"/"+(exp_date.getMonth() + 1)+"/"+exp_date.getFullYear(),
                    isUsed: data.result.isUsed,
                    logins: data.result.logins
                });
            } else {
                this.setState({
                    hasInternet: true,
                    isValidCode: false,
                });
            }
        }).catch(() => {
            this.setState({
                hasInternet: false,
            });
        });
    }

    handleLoginClick(userid, name) {
        this.setState({
            login: userid,
            name: name,
            logins: null,
        });
    }

    handleNumericButtonClick(number) {
        if (number == "<-") {
            this.setState(prevState => ({
                paymentAmount: prevState.paymentAmount.slice(0, -1),
            }));
        } else {
            this.setState(prevState => ({
                paymentAmount: prevState.paymentAmount+number,
            }));
        }
    }

    handleSubmitPaymentButtonClick() {
        store.dispatch(setLoader({
            title: "Even geduld...",
            message: "Je betaling wordt uitgevoerd",
        }));

        let formData = new FormData();
        formData.append('barcode', this.state.barcode);
        formData.append('userid', this.state.login);
        formData.append('amount', this.state.paymentAmount);
        formData.append('type', "betaling");
        formData.append('passphrase', window.localStorage.getItem('passphrase'));

        fetch("https://intranet.foubert.eu/api/cadeau.php?action=add_payment",{
            method: 'POST', 
            body: formData,
        }).then(function(response) {
            store.dispatch(clearLoader());
            return response.json();
        }).then(data => {
            if (data.status == 201) {
                this.handleSubmitSucces();
            } else {
                this.handleSubmitFailure(data.message);
            }
        }).catch(error => {
            this.handleSubmitFailure("An unknown error occured");
        });
    }

    handleSubmitFailure(message) {
        store.dispatch(setToast({
            message: message,
            theme: "error"
        }));
    }

    handleSubmitSucces() {
        store.dispatch(setToast({
            message: "Betaling uitgevoerd.",
        }));

        this.setState({
            login: null,
            displayLogin: false,
        });

        this.getChequeInfo(this.state.barcode);
    }
    
    render() {
        const divStyle = {
            textAlign: "center",
        };

        const bStyle = {
            display: "inline-block",
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            fontSize: "250%"
        }

        const pStyle = {
            display: "inline-block",
            fontSize: "150%",
            marginBottom: "0rem",
        }

        const smallpStyle = {
            marginTop: "2rem",
            marginBottom: "0rem",
        }

        const h2Style = {
            display: "inline-block",
            fontSize: "150%",
            marginTop: "1rem",
            marginBottom: "2rem",
        }

        const imgStyle = {
            maxWidth: "15rem",
            maxHeight: "15rem",
            borderRadius: "5px",
        }

        const loginsScreen = ((this.state.logins && this.state.displayLogin) ? <div>
            <h2 style={h2Style}>Kies je naam:</h2><br />
            {this.state.logins.map(login => <LoginOption
                userid = {login.id}
                name= {login.naam+" "+login.voornaam}
                onClick={this.handleLoginClick}
            />)}
        </div> : null);

        const paymentScreen = (this.state.login ? <div>
            <h2 style={h2Style}>Betaling uitvoeren: {this.state.name}</h2><br />
            <h3>€ {this.state.paymentAmount}</h3><br />
            <br />

            <NumericButton number='1' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='2' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='3' onClick={this.handleNumericButtonClick}/>
            <br />

            <NumericButton number='4' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='5' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='6' onClick={this.handleNumericButtonClick}/>
            <br />

            <NumericButton number='7' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='8' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='9' onClick={this.handleNumericButtonClick}/>
            <br />

            <NumericButton number='<-' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='0' onClick={this.handleNumericButtonClick}/>
            <NumericButton number='.' onClick={this.handleNumericButtonClick}/>
            <br />
            <NumericButton number='Betaling uitvoeren' onClick={this.handleSubmitPaymentButtonClick} isSubmit='true' />
        </div> : null);

        if (this.state.hasInternet) {
            if (this.state.barcode == null) {
                return (
                    <main>
                        <div className="content container">
                            <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div style={{background: "url('/images/menu/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                    </div>
                                </div>
                            </div>
                            <div style={divStyle}>
                                <b style={bStyle}>Scan je QR-code op je cadeaubon</b><br />
                                <p style={pStyle}>Zo kan je live bekijken hoeveel krediet er nog op je cadeaubon staat.</p>
                            </div>
                        </div>
                    </main>
                )
            } else {
                if (this.state.isValidCode == true) {
                    const bijnaOp = ((this.state.isUsed && this.state.restValue < 10) ? <div id="kredietBijnaOp">
                        <b style={bStyle}>Je krediet is bijna op!</b><br />
                        <p style={pStyle}>Laad hem snel bij!</p>
                    </div> : null);
                    return (
                        <main>
                            <div className="content container" style={{textAlign: "center"}}>
                                <img className="cadeauchequeimage" style={imgStyle} src={this.state.cadeauchequeimage} />
                                <div style={divStyle}>
                                    <h2 style={h2Style}>Cadeaubon</h2><br />
                                    <b style={bStyle}>Saldo: € {this.state.restValue}</b><br />
                                    <p style={pStyle}>Geldig tem: {this.state.exp_date}</p><br />
                                    <p style={pStyle}>Unieke code: {this.state.barcode}</p>
                                    <p style={smallpStyle}>
                                        <b>Waar kan ik deze bon uitgeven?</b><br />
                                        <p style={{textAlign: "left", display: "inline-block"}}>
                                            Foubert Sint-Niklaas &#8209; Stationsstraat 127, 9100 Sint-Niklaas <br />
                                            Foubert Antwerpen &#8209; Grote Markt 31, 2000 Antwerpen <br />
                                            Centerken &#8209; Stationsstraat 131, 9100 Sint-Niklaas <br />
                                            Foubert webshop &#8209; <a href="https://shop.foubert.eu/" target="_blank">shop.foubert.eu</a><br />
                                            De ijskarren van Foubert<br />
                                        </p>
                                    </p>

                                    {/* {bijnaOp} */}
                                    {loginsScreen}
                                    {paymentScreen}
                                </div>
                            </div>
                        </main>
                    )
                } else if (this.state.isValidCode == false) {
                    return (
                        <main>
                            <div className="content container">
                                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div style={{background: "url('/images/menu/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div style={divStyle}>
                                    <b style={bStyle}>Je cadeaubon is niet gevonden!</b><br />
                                    <p style={pStyle}>Waarschijnlijk is ze nog niet geactiveerd. Ga langs bij een van onze verkooppunten om je code te activeren.</p>
                                </div>
                            </div>
                        </main>
                    )
                } else {
                    return (
                        <main>
                            <div className="content container">
                                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div style={{background: "url('/images/menu/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                        </div>
                                    </div>
                                </div>
                                <div style={divStyle}>
                                    <b style={bStyle}>Even geduld...</b><br />
                                    <p style={pStyle}>We vragen je gegevens op</p>
                                </div>
                            </div>
                        </main>
                    )
                }
            }
        } else {
            return (
                <main>
                    <div className="content">
                        <ErrorImage text="Je bent offline" subtext="Sommige onderdelen van de app werken mogelijk niet"/>
                    </div>
                </main>
            )
        }
    }
}

export default CadeauView