import React, {Component} from "react"
import ErrorImage from '../ErrorImage';

class StatusView extends Component {
    constructor() {
        super();
        this.state = {
            hasInternet: true,
            karren: [],
        };

        this.checkServerHeartbeat = this.checkServerHeartbeat.bind(this);
        this.fetchStatusinfo = this.fetchStatusinfo.bind(this);
    }
    
    componentDidMount() {
        this.checkServerHeartbeat();
    }

    checkServerHeartbeat() {
        fetch("https://intranet.foubert.eu/api/frontend.php?action=get_heartbeat",{
            method: 'POST', 
        }).then(() => {
            this.setState({
                hasInternet: true,
            });
            setTimeout(this.fetchStatusinfo, 1000);
        }).catch(() => {
            this.setState({
                hasInternet: false,
            });
            setTimeout(this.checkServerHeartbeat, 1000);
        });
    }

    fetchStatusinfo() {
        const url = "https://intranet.foubert.eu/api/frontend.php?action=get_statusinfo";
        fetch(url)
        .then(response => response.json())
        .then(data => {
            const karren = data.results;
            console.log(karren);

            this.setState({
                karren: karren,
            });

            setTimeout(this.fetchStatusinfo, 1000);
        })
        .catch(() => {
            setTimeout(this.checkServerHeartbeat, 1000);
        });
    }

    render() {
        if (this.state.hasInternet) {
            if (this.state.karren.length > 0) {
                const overzicht = this.state.karren.map(kar => <tr>
                    <td style={{paddingRight: "20px"}}><img src={kar.Icon} style={{width: "15px", marginRight:"5px"}}/><b>{kar.Karname}</b></td>
                    <td>{kar.Status}</td>
                </tr>);
                return (
                    <div className="content container">
                        <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                </div>
                            </div>
                            {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                        </div>
                        <h2>Status</h2>
                        <p>Dit is de huidige statussen van onze ijskarren:</p>
                        <ul>
                            <table>
                                {overzicht}
                            </table>
                        </ul>
                    </div>
                )
            } else {
                return (
                    <div className="content container">
                        <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div style={{background: "url('/images/about/header.png') no-repeat center"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                                </div>
                            </div>
                            {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a> */}
                        </div>
                        <h2>Status</h2>
                        <p>De statussen wordt opgehaald...</p>
                    </div>
                )
            }
        } else {
            return (
                <main>
                    <div className="content">
                        <ErrorImage text="Je bent offline" subtext="Sommige onderdelen van de app werken mogelijk niet"/>
                    </div>
                </main>
            )
        }
    }
}

export default StatusView