import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import ErrorImage from '../ErrorImage';
import { throwStatement } from "@babel/types";
import {store} from "../../App";
import { connect } from 'react-redux';

class SetCookieView extends Component {
    constructor(match) {
        super();
        this.state = {
            passphrase: match.match.params.passphrase || null,
            zaakids: match.match.params.zaakids || null,
        };
    }
    
    componentDidMount() {
        if (this.state.passphrase != null) {
            this.setPassphrase(this.state.passphrase, this.state.zaakids);
        } else {
            window.localStorage.removeItem('passphrase');
            window.localStorage.removeItem('zaakids');
        }
    }

    setPassphrase(passphrase, zaakids) {
        window.localStorage.setItem('passphrase', passphrase);
        if (zaakids != null) {
            window.localStorage.setItem('zaakids', zaakids);
        }
    }
    
    render() {
        const divStyle = {
            textAlign: "center",
        };

        const bStyle = {
            display: "inline-block",
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            fontSize: "250%"
        }

        const pStyle = {
            display: "inline-block",
            fontSize: "150%",
            marginBottom: "0rem",
        }

        const h2Style = {
            display: "inline-block",
            fontSize: "150%",
            marginTop: "1rem",
            marginBottom: "2rem"
        }

        return (
            <main>
                <div className="content container">
                    <div style={divStyle}>
                        <b style={bStyle}>OK!</b><br />
                        <p style={pStyle}>Je toestel is ingesteld</p>
                    </div>
                </div>
            </main>
        );
    }
}

export default SetCookieView