import React, {Component} from "react"
import { Redirect, Link } from "react-router-dom";
import {store} from "../../App";
import { setAddressOptions, makeCallOnOpen } from "../../Redux/TrackerActions";
import { connect } from 'react-redux';
import { setToast, setLoader, clearLoader } from "../../Redux/OverlayActions";

class AddressView extends Component {
    constructor() {
        super();
        this.state = {
            empty: ((window.localStorage.getItem('placeid') || '') == ''),
            straat: window.localStorage.getItem('straat') || '',
            nr: window.localStorage.getItem('nr') || '',
            gemeente: window.localStorage.getItem('gemeente') || '',
            postcode: window.localStorage.getItem('postcode') || '',
            land: window.localStorage.getItem('land') || '',
            placeid: 0,

            straatValid: "",
            nrValid: "",
            gemeenteValid: "",
            postcodeValid: "",
            landValid: "",

            continuesValidation: false,

            redirectToTracker: false,
            redirectToAmbiguous: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitFailure = this.handleSubmitFailure.bind(this);
        this.handleSubmitSucces = this.handleSubmitSucces.bind(this);
        this.handleSubmitAmbiguousAdres = this.handleSubmitAmbiguousAdres.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.setAdresStorage = this.setAdresStorage.bind(this);
    }

    validateForm(showToast) {
        let ret = true;

        let straatValid= "is-valid";
        let nrValid= "is-valid";
        let gemeenteValid= "is-valid";
        let postcodeValid= "is-valid";
        let landValid= "is-valid";

        if (this.state.straat == "") {
            straatValid = "is-invalid";
            ret = false;
        }
        if (this.state.nr == "") {
            nrValid = "is-invalid";
            ret = false;
        }
        if (this.state.gemeente == "") {
            gemeenteValid = "is-invalid";
            ret = false;
        }
        if (this.state.postcode == "") {
            postcodeValid = "is-invalid";
            ret = false;
        }
        if (this.state.land == "") {
            landValid = "is-invalid";
            ret = false;
        }

        this.setState({
            straatValid: straatValid,
            nrValid: nrValid,
            gemeenteValid: gemeenteValid,
            postcodeValid: postcodeValid,
            landValid: landValid,
        });
        
        if (!ret && showToast) {
            store.dispatch(setToast({
                message: "Sommige gegevens zijn niet correct ingevuld!",
                theme: "error",
            }));
        }
        return ret;
    }

    async handleChange(event) {
        const {name, value, type, checked} = event.target;
        const target = event.target;
        let cursor = -1;
        
        if (target.setSelectionRange &&
            (
                type === 'text' ||
                type === 'search' ||
                type === 'password' ||
                type === 'url' ||
                type === 'tel'
            )
        ) {
            cursor = ('selectionStart' in event.target ? (event.target.selectionStart ?? -1) : -1);
        }
        await type === "checkbox" ? this.setState({ [name]: checked }) : this.setState({ [name]: value });
        if (cursor != -1 && target.setSelectionRange) {
            target.setSelectionRange(cursor, cursor);
        }
        if (this.state.continuesValidation) {
            this.validateForm(false);
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.validateForm(true)) {
            this.setState({
                continuesValidation: true,
            });
        } else {
            store.dispatch(setLoader({
                title: "Even geduld...",
                message: "We verwerken je adres",
            }));

            let formData = new FormData();
            formData.append('straat', this.state.straat);
            formData.append('nr', this.state.nr);
            formData.append('gemeente', this.state.gemeente);
            formData.append('postcode', this.state.postcode);
            formData.append('land', this.state.land);

            const url = process.env.REACT_APP_API_HREF+"legacycopy.php?action=get_latlng&straat="+this.state.straat+"&nr="+this.state.nr+"&gemeente="+this.state.gemeente+"&postcode="+this.state.postcode+"&land="+this.state.land;
            fetch(url ,{
                method: 'GET',
            }).then(function(response) {
                store.dispatch(clearLoader());
                return response.json();
            }).then(data => {
                if (data.status == 200) {
                    const result = data.result;
                    this.handleSubmitSucces(result);
                } else if (data.status == 409) {
                    const results = data.results;
                    this.handleSubmitAmbiguousAdres(results);
                } else {
                    this.handleSubmitFailure();
                }
            }).catch(error => {
                console.log(error);
                this.handleSubmitFailure();
            });
        }
    }

    handleSubmitFailure() {
        store.dispatch(setToast({
            message: "Er ging iets mis bij het opslaan van je locatie. Probeer het later opnieuw.",
            theme: "error",
        }));
    }

    handleSubmitSucces(result) {
        this.setAdresStorage(result.latitude, result.longitude, result.adres, this.state.straat, this.state.nr, this.state.gemeente, this.state.postcode, this.state.land, result.placeid);

        store.dispatch(makeCallOnOpen());

        this.setState({
            redirectToTracker: true,
        });
    }

    handleSubmitAmbiguousAdres(options) {
        store.dispatch(setAddressOptions(options));

        this.setState({
            redirectToAmbiguous: true,
        });
    }

    setAdresStorage(latitude, longitude, adres, straat, nr, gemeente, postcode, land, place_id) {
        window.localStorage.setItem('latitude', latitude);
        window.localStorage.setItem('longitude', longitude);
        window.localStorage.setItem('adres', adres);
        window.localStorage.setItem('straat', straat);
        window.localStorage.setItem('nr', nr);
        window.localStorage.setItem('gemeente', gemeente);
        window.localStorage.setItem('postcode', postcode);
        window.localStorage.setItem('land', land);
        window.localStorage.setItem('placeid', place_id);
    }
    
    render() {
        if (this.state.redirectToTracker === true) {
            return <Redirect to='/' />
        }
        if (this.state.redirectToAmbiguous === true) {
            return <Redirect to='/address/ambiguous' />
        }

        const h2 = (this.state.empty ? <h2>Adres invullen</h2> : <h2>Adres wijzigen</h2>);
        const p = (this.state.empty ? <p>Vul je gegevens in en druk op opslaan om een oproep te plaatsen</p> : <p>Verbeter je gegevens en druk op opslaan om een oproep te plaatsen</p>);

        return (
            <div className="content container">
                {h2}
                {p}
                
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="form-group col-9">
                            <label htmlFor="formStreet">Straat</label>
                            <input                            
                                type="text" 
                                value={this.state.straat} 
                                name="straat" 
                                placeholder="Bv. Chocoladelaan" 
                                onChange={this.handleChange}
                                id="formStreet"
                                className={"form-control "+this.state.straatValid}
                            />
                            <div className="invalid-feedback">Gelieve een straat in te voeren</div>
                        </div>
                        <div className="form-group col-3 pl-1">
                            <label htmlFor="formStreetNumber">Nummer</label>
                            <input                            
                                type="text" 
                                value={this.state.nr} 
                                name="nr" 
                                placeholder="Bv. 69" 
                                onChange={this.handleChange} 
                                id="formStreetNumber"
                                className={"form-control "+this.state.nrValid}
                            />
                            <div className="invalid-feedback">Gelieve een nr in te voeren</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-8">
                            <label htmlFor="formCounty">Gemeente</label>
                            <input                            
                                type="text" 
                                value={this.state.gemeente} 
                                name="gemeente" 
                                placeholder="Bv. Sint-Niklaas" 
                                onChange={this.handleChange}  
                                id="formCounty"
                                className={"form-control "+this.state.gemeenteValid}
                            />
                            <div className="invalid-feedback">Gelieve een gemeente in te voeren</div>
                        </div>
                        <div className="form-group col-4 pl-1">
                            <label htmlFor="formZipCode">Postcode</label>
                            <input                            
                                type="text" 
                                value={this.state.postcode} 
                                name="postcode" 
                                placeholder="Bv. 9100" 
                                onChange={this.handleChange} 
                                id="formZipCode"
                                className={"form-control "+this.state.postcodeValid}
                            />
                            <div className="invalid-feedback">Gelieve een postcode in te voeren</div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="formCountry">Land</label>
                        <input                            
                            type="text" 
                            value={this.state.land} 
                            name="land" 
                            placeholder="Bv. België" 
                            onChange={this.handleChange}
                            id="formCountry"
                            className={"form-control "+this.state.landValid}
                        />
                        <div className="invalid-feedback">Gelieve een land in te voeren</div>
                    </div>

                    <input type="submit" className="btn btn-primary text-light col-12 mb-4" value="Opslaan" />
                </form>
            </div>
        )
    }
}

export default AddressView